import SideNavigation, {
  SideNavigationProps,
} from '@cloudscape-design/components/side-navigation';
import { useLocation, useNavigate } from 'react-router';

export function Navigation() {
  const favAccounts: SideNavigationProps.Link[] = [
    {
      type: 'link',
      text: 'NYTimes',
      href: '/organizations/org_8ad8bff0-b916-43ea-9946-c09478d20e0d/accounts/ac_ae61e58e-ab51-42fc-a305-97d0da541aee/',
    },
    {
      type: 'link',
      text: 'Yoti',
      href: '/organizations/org_73eac543-f431-440c-9ba1-6446763cc18e/accounts/ac_72273064-766b-4a77-aa32-443805f20ed0/',
    },
    {
      type: 'link',
      text: 'Dexcom',
      href: '/organizations/org_52d19aa2-1e90-4b8a-87af-47a90089c126/accounts/ac_23b3d9ee-4fae-455b-b3c8-bf7cf6463073/',
    },
    {
      type: 'link',
      text: 'Millennium',
      href: '/organizations/org_a11b908f-269a-4582-a4f6-16bfedba14a4/accounts/ac_2e14dee6-a472-4185-abf6-588242165c8a/',
    },
    {
      type: 'link',
      text: 'Venmo-Production',
      href: '/organizations/org_cb61bfe4-9e94-4859-a565-23c911a95add/accounts/ac_1eb82bd9-4bff-4ab8-9108-f312d769980a/',
    },
    {
      type: 'link',
      text: 'Venmo-QA',
      href: '/organizations/org_64bf05c8-68f6-43ea-b31e-172a36c5501c/accounts/ac_499a56a0-9608-44fe-85b8-7a23562787c8/',
    },
    {
      type: 'link',
      text: 'Camptocamp',
      href: '/organizations/org_216f093a-d1d4-4000-ba19-ed3a4d88bc10/accounts/ac_58467cf8-fbde-4bf8-a1ae-6af183dc482e/',
    },
    {
      type: 'link',
      text: 'Swisscom Banking',
      href: '/organizations/org_130544bc-9620-4de0-8f3a-741aea01f03d/accounts/ac_541cf817-7ee1-4ff3-a7f9-0edbdbc5ade2/',
    },
    {
      type: 'link',
      text: 'CFACorp',
      href: '/organizations/org_484785c4-4468-470c-a47e-f5bd078d3cee/accounts/ac_409a9c5f-6c1f-4696-a15c-08870f5bf53e/',
    },
    {
      type: 'link',
      text: 'cts',
      href: '/organizations/org_035e43de-f7b2-472f-a9dc-752771791bd1/accounts/ac_8f1d075b-6207-48b7-bb04-ba6548e1925e/',
    },
    {
      type: 'link',
      text: 'Censhare',
      href: '/organizations/org_0222b982-12a3-4129-af53-0fb8b0b6a2ac/accounts/ac_e8f42749-d452-4edd-b1e6-e9760b6dd9a6/',
    },
    {
      type: 'link',
      text: 'MasterClass',
      href: '/organizations/org_b544afbe-75ae-4077-a476-649777b0614c/accounts/ac_42e7b28e-b9f9-47ed-9a23-11419772283b/',
    },
    {
      type: 'link',
      text: 'Alkira',
      href: '/organizations/org_9632424d-1bca-4827-9aa2-4eb0a93adf90/accounts/ac_dc95680d-5c75-4161-9280-9738713cc5a5/',
    },
    {
      type: 'link',
      text: 'Fairtiq',
      href: '/organizations/org_1eea52e2-0396-44e6-9105-b225d04ddf63/accounts/ac_2931aa0c-b7c2-454e-bdee-d1cb3e9ca029/',
    },
    {
      type: 'link',
      text: 'Allianz',
      href: '/organizations/org_41af9a14-69cd-4249-a4c0-a989573697a2/accounts/ac_c1cd1475-0219-40a2-aff2-b5672a6c9935/',
    },
  ];
  favAccounts.sort((a, b) =>
    a.text.toLowerCase().localeCompare(b.text.toLowerCase()),
  );

  const navItems: SideNavigationProps['items'] = [
    { type: 'link', text: 'Dashboard', href: '/' },
    { type: 'link', text: 'Organizations', href: '/organizations' },
    {
      type: 'section',
      text: 'Favorite Accounts',
      items: favAccounts,
    },
    {
      type: 'section',
      text: 'Addon Hub',
      items: [{ type: 'link', text: 'Addons', href: '/addons' }],
    },
    {
      type: 'section-group',
      title: 'Upgrades',
      items: [
        {
          type: 'section',
          text: 'Plans',
          items: [
            {
              type: 'link',
              text: 'My Plans',
              href: '/upgrades?mine=true',
            },
            {
              type: 'link',
              text: 'Pending Plans',
              href: '/upgrades?status=pending',
            },
            { type: 'link', text: 'All Plans', href: '/upgrades' },
          ],
        },
        {
          type: 'section',
          text: 'Templates',
          items: [
            {
              type: 'link',
              text: 'My Templates',
              href: '/upgrade_templates?mine=true',
            },
            {
              type: 'link',
              text: 'Pending Templates',
              href: '/upgrade_templates?status=pending',
            },
            {
              type: 'link',
              text: 'All Templates',
              href: '/upgrade_templates',
            },
          ],
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      type: 'section',
      text: 'Midas',
      items: [
        { type: 'link', text: 'My Queries', href: '/midas/queries?show=mine' },
        {
          type: 'link',
          text: 'Shared Queries',
          href: '/midas/queries?show=shared',
        },
        { type: 'link', text: 'Table Browser', href: '/midas/tables' },
      ],
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <SideNavigation
      items={navItems}
      activeHref={location.hash}
      onFollow={(event) => {
        navigate(event.detail.href);
        event.preventDefault();
      }}
    />
  );
}
